import addMonths from "date-fns/addMonths";
import isValid from "date-fns/isValid";
import lightFormat from "date-fns/lightFormat";
import { useEffect } from "react";
import { InterestRate, Prepayment } from "./store";

const SESSION_KEY = "SESSION_ID";
const USER_KEY = "USER_ID";

export interface Payment {
  type: "rate" | "prepayment" | "payment";
  date: string;
  rate?: number;
  class?: {
    [key: string]: boolean;
  };
  amount?: number;
  principalPayment?: number;
  interestPayment?: number;
  installment?: number;
  principal?: number;
  firstRow?: boolean;
}

export function getRate(interestRates: InterestRate[], date: string) {
  let lastRate = interestRates[0];
  for (let rate of interestRates) {
    if (rate.date < date) {
      lastRate = rate;
    }
  }
  return lastRate;
}

function generatePrepaymentRow(prepayment: Prepayment): Payment {
  return {
    type: "prepayment",
    date: prepayment.date,
    amount: prepayment.amount,
    class: {
      "table-success": true,
      "text-center": true,
    },
  };
}

function generatePaymentRow(
  date: string,
  installment: number,
  principal: number,
  principalPayment: number,
  interestPayment: number,
  i: number
): Payment {
  return {
    type: "payment",
    date,
    installment,
    principal,
    principalPayment,
    interestPayment,
    firstRow: i === 0,
  };
}

function generateRateRow(
  date: string,
  rate: number,
  rateDiff: number
): Payment {
  return {
    type: "rate",
    date,
    rate,
    class: {
      "table-danger": rateDiff > 0,
      "table-success": rateDiff < 0,
      "text-center": true,
    },
  };
}

export function calculateInstallment(
  amount: number,
  duration: number,
  interestRate: number
) {
  if (interestRate === 0) {
    return amount / duration;
  }
  let rate = interestRate / 100 / 12;
  return Math.round((rate / (1 - (1 + rate) ** -duration)) * amount);
}

export function calculateAmount(
  duration: number,
  interestRate: number,
  installment: number
) {
  if (interestRate === 0) {
    return installment * duration;
  }
  let rate = interestRate / 100 / 12;
  return (installment * (1 - 1 / Math.pow(1 + rate, duration))) / rate;
}

export function calculateDuration(
  amount: number,
  interestRate: number,
  installment: number
) {
  if (interestRate === 0) {
    return roundMonth(amount / installment);
  }
  let rate = interestRate / 100 / 12;
  return -roundMonth(logn(1 + rate, 1 - (rate * amount) / installment));
}

export function calculateRate(
  amount: number,
  duration: number,
  installment: number
) {
  if (duration * installment <= amount) {
    return 0;
  }
  let d = Infinity;
  let bestGuess = 0;
  for (let guess = 0; guess < 100; guess += 0.01) {
    const gi = calculateInstallment(amount, duration, guess);
    const newd = Math.abs(gi - installment);
    if (newd < d) {
      d = newd;
      if (d < 1) {
        return guess;
      }
      bestGuess = guess;
    }
  }
  return bestGuess;
}

function logn(base: number, val: number) {
  return Math.log(val) / Math.log(base);
}

function roundMonth(month: number) {
  const imonth = Math.trunc(month);
  if (month - imonth < 0.1) {
    return imonth;
  }
  return imonth + 1;
}

function calculatePrepayment(prepayments: Prepayment[], date: string) {
  return prepayments.find((prepayment) => date === prepayment.date);
}

export function payments(
  interestRates: InterestRate[],
  prepayments: Prepayment[],
  duration: number,
  amount: number
): Payment[] {
  const ret: Payment[] = [];
  let act = interestRates[0].date;
  let rate = 0;
  let lastPrincipal = amount;
  let prepayment: Prepayment | undefined;

  if (!isValid(new Date(act))) {
    return ret;
  }

  let installment = calculateInstallment(lastPrincipal, duration, rate);
  for (let i = 0; i < duration; i++) {
    const newRateObj = getRate(interestRates, act);
    const newRate = newRateObj.rate;
    const rateDiff = newRate - rate;
    if (rateDiff !== 0 || prepayment?.type === "installment") {
      rate = newRate;
      if (i > 0 && rateDiff !== 0) {
        let row = generateRateRow(newRateObj.date, newRate, rateDiff);
        ret.push(row);
      }
      installment = calculateInstallment(lastPrincipal, duration - i, rate);
    }
    const interestPayment = (lastPrincipal * rate) / 100 / 12;
    let principalPayment = installment - interestPayment;
    prepayment = calculatePrepayment(prepayments, act);
    if (prepayment) {
      ret.push(generatePrepaymentRow(prepayment));
    }

    //Last payment, where the installment can be less than the others
    const prepaymentAmount = prepayment?.amount ?? 0;
    if (lastPrincipal <= principalPayment + prepaymentAmount) {
      principalPayment = Math.max(0, lastPrincipal - prepaymentAmount);
      installment = principalPayment + interestPayment;
      lastPrincipal = 0;
    } else {
      lastPrincipal -= principalPayment + prepaymentAmount;
    }
    let row = generatePaymentRow(
      act,
      installment,
      lastPrincipal,
      principalPayment,
      interestPayment,
      i
    );
    ret.push(row);
    if (lastPrincipal <= 1000) {
      break;
    }

    act = lightFormat(addMonths(new Date(act), 1), "yyyy-MM-dd");
  }
  return ret;
}

export function formatCurrency(lang: string, num: number) {
  if (lang === "hu") {
    return NUM_FORMAT_HU.format(num);
  }
  return NUM_FORMAT_EN.format(num);
}

const NUM_FORMAT_HU = new Intl.NumberFormat("hu-HU", {
  style: "currency",
  currency: "HUF",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const NUM_FORMAT_EN = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const PERCENT_FORMAT = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 2,
});

export function getMaxMonthlyPayment(lang: string): string {
  if (lang === "hu") {
    return "300000";
  } else {
    return "10000";
  }
}

export function getDefaultAmount(lang: string): number {
  if (lang === "hu") {
    return 9_000_000;
  } else {
    return 200_000;
  }
}

function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}

function useSessionToken() {
  let sessionToken = sessionStorage.getItem(SESSION_KEY);
  if (sessionToken) {
    return sessionToken;
  } else {
    sessionToken = guidGenerator();
    sessionStorage.setItem(SESSION_KEY, sessionToken);
    return sessionToken;
  }
}

function useUserToken() {
  let userToken = localStorage.getItem(USER_KEY);
  if (userToken) {
    return userToken;
  } else {
    userToken = guidGenerator();
    localStorage.setItem(USER_KEY, userToken);
    return userToken;
  }
}

export function useAnalytics() {
  const sessionToken = useSessionToken();
  const userToken = useUserToken();

  useEffect(() => {
    function dispatchEvent() {
      fetch("/event", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": " application/json"
        },
        body: JSON.stringify({
          site: location.hostname,
          userToken,
          sessionToken,
          lang: navigator.language,
          size: `${window.innerWidth}x${window.innerHeight}`,
        }),
      });
    }
    dispatchEvent();
  }, []);
}
